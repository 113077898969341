html, body {
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-style: normal;
    font-size: 100%;
    line-height: 1;
    font-family: inherit;
  }
  
  article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display: block;
  }
  
  audio[controls], canvas, video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
  }
  
  .clearfix {
    zoom: 1;
  }
  
  .clearfix:before, .clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
  }
  
  .clearfix:after {
    clear: both;
  }
  
  html {
    overflow-y: scroll;
    background: #ffffff;
    font-size: 62.5%
  }
  
  h1 {
    font-size: 48px;
    font-size: 4.8rem;
    line-height: 1.2;
  }
  
  h2 {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 1.2;
  }
  
  h3 {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.3;
  }
  
  h4 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.25;
  }
  
  h5 {
    font-size: 16px;
    font-size: 1.6rem;
  }
  
  h6 {
    font-size: 16px;
    font-size: 1.6rem;
  }
  
  p, ol, ul, dl, address {
    margin-bottom: 1.5em;
    font-size: 16px;
    font-size: 1.6rem;
  }
  
  ul, ol {
    margin: 0;
    padding: 0;
  }
  
  li ul, li ol {
    margin: 0;
    font-size: 16px;
    font-size: 1.6rem;
  }
  
  dl, dd {
    margin: 0;
  }
  
  dt {
    font-weight: normal;
  }
  
  blockquote {
    margin: 0;
    padding: 0;
    font-style: italic;
  }
  
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }
  
  abbr[title] {
    border-bottom: 1px dotted;
    cursor: help;
  }
  
  b, strong {
    font-weight: bold;
  }
  
  dfn {
    font-style: italic;
  }
  
  hr {
    display: block;
    margin: 1em 0;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid #bfbfbf;
  }
  
  ins {
    background-color: #c3d9e6;
    color: #000000;
    text-decoration: none;
  }
  
  mark {
    background-color: #c3d9e6;
    color: #000000;
    font-style: italic;
    font-weight: bold;
  }
  
  pre, code, kbd, samp {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5em;
  }
  
  pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  q {
    quotes: none;
  }
  
  q:before, q:after {
    content: "";
    content: none;
  }
  
  small {
    font-size: 14px;
    font-size: 1.4rem;
  }
  
  img {
      width: auto ;
      height: auto ;
  }
  
  sub, sup {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  
  sup {
    top: -0.5em;
  }
  
  sub {
    bottom: -0.25em;
  }
  
  a {
    text-decoration: none;
    -webkit-transition: color 0.2s ease-out;
    -moz-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out;
  }
  
  a:focus {
    outline: thin dotted;
  }
  
  a:hover, a:active {
    outline: 0;
  }
  
  figure img, figure object, figure embed {
    max-width: 100%
  }
  
  figcaption {
    display: block;
    font-weight: normal;
  }
  
  img {
    border: 0;
  }
  
  svg:not(:root) {
    overflow: hidden;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%
  }
  
  caption {
    background-color: transparent;
  }
  
  form {
    margin: 0;
  }
  
  fieldset {
    padding: 0;
    border-width: 0;
  }
  
  legend {
    *margin-left: -7px;
    padding: 0;
    border-width: 0;
  }
  
  label {
    font-weight: normal;
    cursor: pointer;
  }
  
  button, input, select, textarea {
    font-size: 12px;
    font-size: 1.2rem;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
  }
  
  button, input {
    line-height: normal;
    *overflow: visible;
  }
  
  button, input[type="button"], input[type="reset"], input[type="submit"] {
    cursor: pointer;
  }
  
  input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
  }
  
  input[type="search"] {
    box-sizing: content-box;
  }
  
  button:-moz-focus-inner, input:-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  
  textarea {
    overflow: auto;
    vertical-align: top;
  }
  
  html {
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.25);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
  }
  
  :-webkit-selection {
    background: #009fde;
    color: #fff;
    text-shadow: none;
  }
  
  :-moz-selection {
    background: #009fde;
    color: #fff;
    text-shadow: none;
  }
  
  :selection {
    background: #009fde;
    color: #fff;
    text-shadow: none;
  }
  
  button, input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
  }
  
  :-webkit-input-placeholder {
    font-size: 14px;
    font-size: 1.4rem;
  }
  
  input:-moz-placeholder {
    font-size: 14px;
    font-size: 1.4rem;
  }
  
  .ie7 img, .iem7 img {
    -ms-interpolation-mode: bicubic;
  }
  
  input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
  }
  
  input[type="search"] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
  }
  
  button:-moz-focus-inner, input:-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  
  .ir {
    display: block;
    overflow: hidden;
    background-repeat: no-repeat;
    text-align: left;
    text-indent: -999em;
    direction: ltr;
  }
  
  .ir br {
    display: none;
  }
  
  .hidden {
    display: none;
    visibility: hidden;
  }
  
  .visuallyhidden {
    position: absolute;
    margin: -1px;
    padding: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
  }
  
  .visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
    position: static;
    margin: 0;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
  }
  
  .invisible {
    visibility: hidden;
  }
  
  body {
    height: 100%;
    padding: 0;
    font-family: "Gill Sans W04 Book", "Gill Sans", Helvetica, sans-serif;
    margin: 0 auto;
    font-size: 16px;
    font-size: 1.6rem;
    background: #F5F5EC;
  }
  
  .wrapper {
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .pos-ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .pull-left {
    float: left;
  }
  
  .pull-right {
    float: right;
  }
  
  .hide {
    display: none !important;
  }
  
  img {
    display: block;
    max-width: 100%
  }
  
  .noscripts .image-block {
    margin-bottom: 20px;
    height: 450px;
    background: transparent url(../../images/crab.jpg) no-repeat fixed center;
    background-size: cover;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Gill Sans W04 Book", "Gill Sans", Helvetica, sans-serif;
  }
  
  p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 25px;
    text-align: justify;
    text-justify: inter-word;
  }
  
  a, a:active, a:visited {
    color: #111;
    text-decoration: underline;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  blockquote {
    margin: 0;
    margin-top: 50px;
    padding: 20px;
    border: none;
    border-top: 1px solid #111;
    border-bottom: 1px solid #111;
  }
  
  blockquote p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 28px;
    text-align: justify;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px;
  }
  
  blockquote b {
    font-style: normal;
    font-weight: normal;
    color: #111;
  }
  
  dl {
    margin: 5px 0;
  }
  
  dt, dd {
    display: inline-block;
  }
  
  dt {
    width: 105px;
  }
  
  .site-header span, .site-header span a {
    margin: 0 auto;
    width: 240px;
    height: 85px;
  }
  
  .site-header span {
    display: block;
    padding: 25px 0;
    padding-bottom: 35px;
  }
  
  .site-header span a {
    display: block;
    background: transparent url(../../images/menu-logo.png) no-repeat;
    text-indent: -9999em;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .site-header-strapline {
    margin: -15px 0 0;
    padding: 10px 0 15px;
    border-bottom: 0px solid #111;
    font-size: 18px;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
  }
  
  .nav-wrapper {
    padding: 0;
    background: #F5F5EC;
    /*#f9f8f2 url(../../images/paper-bg.jpg);*/
  }
  
  .nav-wrapper.stuck {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    z-index: 50;
    padding-bottom: 0;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }
  
  .nav-wrapper.stuck .site-nav {
    padding: 15px 0;
    padding-bottom: 17px;
  }
  
  .nav-wrapper hr {
    margin: 0;
    border: none;
    border-bottom: 1px solid #111;
  }
  
  .nav-wrapper .tiny-logo {
    display: none;
  }
  
  .site-nav {
    position: relative;
    display: block;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 20px;
  }
  
  .site-nav ul {
    display: none;
  }
  
  .nav-mobile {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 90%;
    height: 34px;
  }
  
  .nav-mobile a span {
    margin-left: 10px;
  }
  
  .nav-mobile .menu-icon {
    display: block;
    width: 18px;
    height: 20px;
    position: absolute;
    right: 0;
    margin-top: 6px;
    margin-right: 10px;
  }
  
  .nav-mobile .menu-icon span {
    width: 100%;
    height: 2px;
    background-color: black;
    margin: 4px 0;
    display: block;
  }
  
  .nav-mobile a.mobile-nav-trigger {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 5px;
    width: 100%;
    font-family: "Gill Sans W04 Book", "Gill Sans", Helvetica, sans-serif;
    font-size: 18px;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: left;
    text-decoration: none;
    border: 1px solid #111;
  }
  
  .mobile-nav-dropdown {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    padding: 10px 0;
    width: 100%;
    border: 1px solid #111;
    background: #fff;
    /*#f9f8f2 url(../../images/paper-bg.jpg);*/
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    z-index: 100;
  }
  
  .mobile-nav-dropdown li {
    text-align: center;
  }
  
  .mobile-nav-dropdown a {
    display: block;
    font-size: 18px;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 34px;
  }
  
  .mobile-nav-dropdown a.sub {
    background-color: #f5f5f5;
    text-transform: none;
    font-size: 15px;
    line-height: 25px;
  }
  
  .nav-section {
    position: relative;
    min-height: 190px;
  }
  
  .nav-section .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    display: none;
    margin-left: -16px;
    margin-top: -16px;
    width: 32px;
    height: 32px;
    background: #f9f8f2 url(../../images/ajax-loader.gif) no-repeat;
    z-index: 1000;
  }
  
  #home {
    min-height: inherit;
  }
  
  .col-right {
    margin-bottom: 30px;
  }
  
  .col-right img {
    margin: 0 auto;
  }
  
  .alt-block .col-left {
    margin-bottom: 30px;
  }
  
  .alt-block .col-left img {
    margin: 0 auto;
  }
  
  .image-block {
    position: relative;
    padding-top: 50%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .image-block span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    -webkit-transition: opacity 4s ease-out;
    -moz-transition: opacity 4s ease-out;
    transition: opacity 4s ease-out;
  }
  
  .image-block span.active {
    opacity: 1;
    -webkit-transition: opacity 4s ease-out;
    -moz-transition: opacity 4s ease-out;
    transition: opacity 4s ease-out;
  }
  
  .image-block.unloaded {
    display: none;
  }
  
  #copper {
    height: 660px;
    background: #dad7c8 url(../../images/copper.jpg) no-repeat center top;
    background-size: cover;
    overflow: visible;
  }
  
  .content-block {
    padding: 30px 0;
    padding-bottom: 0;
  }
  
  .content-block h1 {
    margin: 0;
    margin-bottom: 35px;
    padding-bottom: 20px;
    border-bottom: 1px solid #111;
    font-size: 18px;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
  }
  
  .content-block h2 {
    margin: 0;
    margin-bottom: 35px;
    padding-bottom: 20px;
    border-bottom: 1px solid #111;
    font-size: 18px;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
  }
  
  .content-block h3 {
    margin-top: 20px;
    font-size: 16px;
    font-size: 1.6rem;
    text-transform: uppercase;
    line-height: 25px;
  }
  
  .content-block .opening-times {
    text-transform: uppercase;
  }
  
  .content-block.unloaded {
    display: none;
  }
  
  .text-block {
    position: relative;
    padding-bottom: 30px;
  }
  
  .menu-container {
    position: relative;
    padding-bottom: 70px;
  }
  
  .menu-container #copper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%
  }
  
  .menu {
    position: relative;
    top: 30px;
    margin: 0 10px;
    max-width: 700px;
    background: #fff;
    /*#f9f8f2 url(../../images/paper-bg.jpg);*/
    box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.15), 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  }
  
  .menu-wrapper {
    padding: 50px 5.5% 30px 5.5%
  }
  
  .menu-wrapper .menu-logo-container img {
    margin: 0px auto;
    margin-top: 40px;
    margin-bottom: 20px;
    max-width: 200px;
  }
  
  .menu h2 {
    margin: 0 auto;
    width: 251px;
    height: 88px;
    background: transparent url(../../images/menu-logo.png) no-repeat;
    text-indent: -9999em;
  }
  
  .menu p {
    margin-bottom: 55px;
    text-align: center;
    text-transform: uppercase;
    line-height: 30px;
  }
  
  .menu em {
    font-style: italic;
  }
  
  .menu small {
    display: block;
    text-align: center;
    font-size: 10px;
    font-size: 1rem;
    line-height: 18px;
  }
  
  .menu-table {
    font-size: 12px;
    font-size: 1.2rem;
  }
  
  .menu-table tr td:first-child {
    padding-right: 50px;
  }
  
  .menu-table tr td:last-child {
    width: 10%;
    white-space: nowrap;
  }
  
  .menu-table td {
    padding: 10px 0;
    line-height: 16px;
    vertical-align: top;
  }
  
  .menu-table sub {
    bottom: 0;
    font-size: 14px;
    font-size: 1rem;
  }
  
  .tab-content {
    margin-top: 60px;
    margin-bottom: 50px;
  }
  
  .tab-content>.tab-pane {
    display: none;
  }
  
  .tab-content>.active {
    display: block;
  }
  
  .menu .tab-pane p {
    margin: 0;
    font-size: 1.7rem;
    text-transform: none;
    line-height: 30px;
  }
  
  .menu .tab-pane strong {
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 1.7rem;
    font-weight: normal;
    line-height: 22px;
    text-transform: uppercase;
  }
  
  .fade {
    -webkit-transition: opacity 0.15s linear;
    -moz-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    opacity: 0;
  }
  
  .fade.in {
    opacity: 1;
  }
  
  #map {
    height: 300px;
  }
  
  #map img {
    max-width: inherit;
  }
  
  .vcard {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 27px;
    text-transform: uppercase;
  }
  
  .social-links {
    margin-top: 30px;
  }
  
  .social-links p {
    margin-bottom: 0;
    margin-right: 0;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 35px;
    text-transform: uppercase;
  }
  
  .social-links li {
    float: left;
    margin-right: 12px;
  }
  
  .social-links li:last-child {
    margin-right: 0;
  }
  
  .social-links a {
    position: relative;
    display: block;
    width: 35px;
    height: 35px;
    text-indent: -9999em;
    background: transparent url(../../images/social-links.png) no-repeat;
  }
  
  .social-links .link-twitter {
    background-position: left top;
  }
  
  .social-links .link-facebook {
    background-position: left -35px;
  }
  
  .social-links .link-instagram {
    background-position: left -70px;
  }
  
  .social-links a .border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid #111;
    border-radius: 35px;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  
  .social-links a:hover .border {
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    opacity: 1;
  }
  
  #contactForm {
    margin-bottom: 25px;
  }
  
  #contactForm div.row {
    width: 200px;
    margin-bottom: 5px;
  }
  
  #contactForm input, #contactForm textarea {
    width: 100% !important;
  }
  
  .sub-form {
    margin-top: -10px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .sub-form p {
    text-align: center;
  }
  
  .sub-form legend {
    padding-bottom: 15px;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 35px;
    text-transform: uppercase;
  }
  
  .sub-form label {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
  }
  
  .sub-form input, #contactForm input, #contactForm textarea {
    padding: 6px 10px;
    width: 180px;
    font-family: "Gill Sans W04 Book", "Gill Sans", Helvetica, sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.6);
    border: 1px solid #808080;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-appearance: none;
  }
  
  .sub-form input:focus, #contactForm input:focus, #contactForm textarea:focus {
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #111;
    outline: none;
  }
  
  .sub-form button, #contactForm button {
    display: inline-block;
    padding: 0 18px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 31px;
    text-transform: uppercase;
    color: #fff;
    background: #111;
    appearance: none;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: background 0.4s ease;
    -moz-transition: background 0.4s ease;
    transition: background 0.4s ease;
  }
  
  .sub-form button:hover, #contactForm button:hover {
    background: #555;
  }
  
  #contactForm input.error, #contactForm textarea.error {
    border-color: #cc0000 !important;
  }
  
  #contactForm_confirmation {
    display: none;
    width: 200px;
    height: 203px;
    padding-top: 25px;
    text-align: center;
  }
  
  .alt-block {
    position: relative;
    padding-bottom: 30px;
  }
  
  .alt-block p {
    margin-right: 0;
  }
  
  .newsletter-message {
    position: fixed;
    top: 10%;
    left: 10%;
    right: 10%;
    border: 1px solid #111;
    background: #fff;
    /*#f9f8f2 url(../../images/paper-bg.jpg);*/
    opacity: 0.95;
    z-index: 10000;
  }
  
  .newsletter-message .message-content {
    padding: 30px;
  }
  
  .newsletter-message .message-content h1 {
    font-size: 45px;
    font-size: 4.5rem;
    text-transform: uppercase;
    text-align: center;
  }
  
  .newsletter-message .message-content p {
    margin: 0;
    font-size: 16px;
    font-size: 1.6rem;
    text-align: center;
  }
  
  .site-footer {
    margin-top: 60px;
    padding-top: 35px;
    padding-bottom: 20px;
    border-top: 1px solid #111;
    text-align: center;
  }
  
  .site-footer p {
    margin-bottom: 90px;
    padding: 0 20px;
    font-size: 14px;
    font-size: 1.4rem;
    text-align: center;
  }
  
  .site-footer small {
    font-size: 13px;
    font-size: 1.3rem;
  }
  
  .site-footer .bar {
    padding: 0 20px;
  }
  
  .top-table-widget {
    max-width: 210px;
    background-color: transparent;
    border: 2px solid #111;
    border-radius: 10px;
    text-align: center;
  }
  
  .top-table-widget header {
    margin-bottom: 15px;
    padding: 8px 0;
    border-bottom: 2px solid #111;
    text-align: center;
  }
  
  .top-table-widget header h1 {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 22px;
    color: #111;
    border: none;
  }
  
  .top-table-widget header h2 {
    margin: 0;
    padding: 0;
    font-size: 10px;
    font-size: 1rem;
    line-height: 12px;
    text-transform: uppercase;
    color: #b7b7b7;
    border: none;
  }
  
  .top-table-widget hr {
    margin: 15px 0;
    border-width: 2px;
    border-color: #111;
  }
  
  .top-table-widget input, .top-table-widget select, #frmReservation input, #frmReservation select {
    font-size: 12px;
    font-size: 1.2rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  .top-table-widget input, #frmReservation input {
    padding: 3px 6px;
    border: 1px solid #c1bfbc;
    border-radius: 4px;
  }
  
  .top-table-widget button {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    color: #111;
    background: transparent;
    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }
  
  .top-table-widget button:hover {
    color: #969696;
  }
  
  .input-wrapper {
    position: relative;
    margin: 0 20px;
    padding-left: 35px;
  }
  
  .input-wrapper::before {
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    content: '';
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: transparent url(../../images/icons-toptable.png) no-repeat;
  }
  
  .svg .input-wrapper::before {
    background: transparent url(../../images/icons-toptable.svg) no-repeat;
    background-size: 170px 20px;
  }
  
  .input-wrapper.icon-time::before {
    background-position: -30px top;
  }
  
  .input-wrapper.icon-party::before {
    background-position: -60px top;
  }
  
  .input-wrapper.icon-lock::before {
    background-position: -90px top;
  }
  
  .input-wrapper.icon-nights::before {
    background-position: -120px top;
  }
  
  .input-wrapper.icon-child::before {
    background-position: -150px top;
  }
  
  .twitter-list {
    padding: 0px 20px 20px 0px;
  }
  
  .twitter-list .tweet {
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #cacaca;
  }
  
  .twitter-list .tweet:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
  
  .twitter-list .tweet-text p, .twitter-list .tweet-text p a, .twitter-list .tweet-text time {
    margin: 0;
    font-size: 12px;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
  }
  
  .twitter-list .tweet-text p a {
    font-style: italic;
  }
  
  .twitter-list .tweet-text h3 {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 400;
  }
  
  .twitter-list .tweet-text time {
    color: #999;
  }
  
  span.icon {
    display: block;
    margin: 0 auto;
    padding-top: 50px;
  }
  
  span.icon-instagram {
    background: url(../../images/icon-event-instagram.png) no-repeat center center;
    width: 35px;
    height: 34px;
  }
  
  span.icon-twitter {
    background: url(../../images/icon-event-twitter.png) no-repeat center center;
    width: 41px;
    height: 34px;
  }
  
  @-webkit-keyframes left-right {
    0% {
      -webkit-transform: translateY(-20%) translateX(-10%);
      -moz-transform: translateY(-20%) translateX(-10%);
      -ms-transform: translateY(-20%) translateX(-10%);
      -o-transform: translateY(-20%) translateX(-10%);
      transform: translateY(-20%) translateX(-10%);
    }
    100% {
      -webkit-transform: translateY(0%) translateX(10%);
      -moz-transform: translateY(0%) translateX(10%);
      -ms-transform: translateY(0%) translateX(10%);
      -o-transform: translateY(0%) translateX(10%);
      transform: translateY(0%) translateX(10%);
    }
  }
  
  @-moz-keyframes left-right {
    0% {
      -webkit-transform: translateY(-20%) translateX(-10%);
      -moz-transform: translateY(-20%) translateX(-10%);
      -ms-transform: translateY(-20%) translateX(-10%);
      -o-transform: translateY(-20%) translateX(-10%);
      transform: translateY(-20%) translateX(-10%);
    }
    100% {
      -webkit-transform: translateY(0%) translateX(10%);
      -moz-transform: translateY(0%) translateX(10%);
      -ms-transform: translateY(0%) translateX(10%);
      -o-transform: translateY(0%) translateX(10%);
      transform: translateY(0%) translateX(10%);
    }
  }
  
  @-ms-keyframes left-right {
    0% {
      -webkit-transform: translateY(-20%) translateX(-10%);
      -moz-transform: translateY(-20%) translateX(-10%);
      -ms-transform: translateY(-20%) translateX(-10%);
      -o-transform: translateY(-20%) translateX(-10%);
      transform: translateY(-20%) translateX(-10%);
    }
    100% {
      -webkit-transform: translateY(0%) translateX(10%);
      -moz-transform: translateY(0%) translateX(10%);
      -ms-transform: translateY(0%) translateX(10%);
      -o-transform: translateY(0%) translateX(10%);
      transform: translateY(0%) translateX(10%);
    }
  }
  
  @keyframes left-right {
    0% {
      -webkit-transform: translateY(-20%) translateX(-10%);
      -moz-transform: translateY(-20%) translateX(-10%);
      -ms-transform: translateY(-20%) translateX(-10%);
      -o-transform: translateY(-20%) translateX(-10%);
      transform: translateY(-20%) translateX(-10%);
    }
    100% {
      -webkit-transform: translateY(0%) translateX(10%);
      -moz-transform: translateY(0%) translateX(10%);
      -ms-transform: translateY(0%) translateX(10%);
      -o-transform: translateY(0%) translateX(10%);
      transform: translateY(0%) translateX(10%);
    }
  }
  
  @-webkit-keyframes right-left {
    0% {
      -webkit-transform: translateY(0%) translateX(10%);
      -moz-transform: translateY(0%) translateX(10%);
      -ms-transform: translateY(0%) translateX(10%);
      -o-transform: translateY(0%) translateX(10%);
      transform: translateY(0%) translateX(10%);
    }
    100% {
      -webkit-transform: translateY(-20%) translateX(-10%);
      -moz-transform: translateY(-20%) translateX(-10%);
      -ms-transform: translateY(-20%) translateX(-10%);
      -o-transform: translateY(-20%) translateX(-10%);
      transform: translateY(-20%) translateX(-10%);
    }
  }
  
  @-moz-keyframes right-left {
    0% {
      -webkit-transform: translateY(0%) translateX(10%);
      -moz-transform: translateY(0%) translateX(10%);
      -ms-transform: translateY(0%) translateX(10%);
      -o-transform: translateY(0%) translateX(10%);
      transform: translateY(0%) translateX(10%);
    }
    100% {
      -webkit-transform: translateY(-20%) translateX(-10%);
      -moz-transform: translateY(-20%) translateX(-10%);
      -ms-transform: translateY(-20%) translateX(-10%);
      -o-transform: translateY(-20%) translateX(-10%);
      transform: translateY(-20%) translateX(-10%);
    }
  }
  
  @-ms-keyframes right-left {
    0% {
      -webkit-transform: translateY(0%) translateX(10%);
      -moz-transform: translateY(0%) translateX(10%);
      -ms-transform: translateY(0%) translateX(10%);
      -o-transform: translateY(0%) translateX(10%);
      transform: translateY(0%) translateX(10%);
    }
    100% {
      -webkit-transform: translateY(-20%) translateX(-10%);
      -moz-transform: translateY(-20%) translateX(-10%);
      -ms-transform: translateY(-20%) translateX(-10%);
      -o-transform: translateY(-20%) translateX(-10%);
      transform: translateY(-20%) translateX(-10%);
    }
  }
  
  @keyframes right-left {
    0% {
      -webkit-transform: translateY(0%) translateX(10%);
      -moz-transform: translateY(0%) translateX(10%);
      -ms-transform: translateY(0%) translateX(10%);
      -o-transform: translateY(0%) translateX(10%);
      transform: translateY(0%) translateX(10%);
    }
    100% {
      -webkit-transform: translateY(-20%) translateX(-10%);
      -moz-transform: translateY(-20%) translateX(-10%);
      -ms-transform: translateY(-20%) translateX(-10%);
      -o-transform: translateY(-20%) translateX(-10%);
      transform: translateY(-20%) translateX(-10%);
    }
  }
  
  .sy-box.sy-loading {
    background: url(assets/img/sy-loader.gif) 50% 50% no-repeat;
    -webkit-background-size: 32px;
    -moz-background-size: 32px;
    -o-background-size: 32px;
    background-size: 32px;
    min-height: 40px;
  }
  
  .sy-box.sy-loading .sy-slides-wrap, .sy-box.sy-loading .sy-pager {
    visibility: hidden;
  }
  
  .sy-slides-wrap {
    position: relative;
    height: 100%;
    width: 100%
  }
  
  .sy-slides-wrap:hover .sy-controls {
    display: block;
  }
  
  .sy-slides-crop {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
  }
  
  .sy-list {
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
  }
  
  .sy-list.horizontal {
    -webkit-transition: left ease;
    -moz-transition: left ease;
    -o-transition: left ease;
    transition: left ease;
  }
  
  .sy-slide {
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  
  .sy-slide.kenburns {
    width: 140%;
    left: -20%
  }
  
  .sy-slide.kenburns.useCSS {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
  }
  
  .sy-slide.kenburns.useCSS.sy-ken:nth-child(1n) {
    -webkit-animation-name: left-right;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: left-right;
    -moz-animation-fill-mode: forwards;
    -o-animation-name: left-right;
    -o-animation-fill-mode: forwards;
    animation-name: left-right;
    animation-fill-mode: forwards;
  }
  
  .sy-slide.kenburns.useCSS.sy-ken:nth-child(2n) {
    -webkit-animation-name: right-left;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: right-left;
    -moz-animation-fill-mode: forwards;
    -o-animation-name: right-left;
    -o-animation-fill-mode: forwards;
    animation-name: right-left;
    animation-fill-mode: forwards;
  }
  
  .sy-slide.sy-active {
    z-index: 3;
  }
  
  .sy-slide>a {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%
  }
  
  .sy-slide>a>img {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    border: 0;
  }
  
  .sy-controls {
    display: none;
    list-style: none;
    height: 100%;
    width: 100%;
    position: absolute;
    padding: 0;
    margin: 0;
  }
  
  .sy-controls li {
    position: absolute;
    width: 10%;
    min-width: 4.2em;
    height: 100%;
    z-index: 33;
  }
  
  .sy-controls li.sy-prev {
    left: 0;
    top: 0;
  }
  
  .sy-controls li.sy-prev a:after {
    background-position: -5% 0;
  }
  
  .sy-controls li.sy-next {
    right: 0;
    top: 0;
  }
  
  .sy-controls li.sy-next a:after {
    background-position: 105% 0;
  }
  
  .sy-controls li a {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    text-indent: -9999px;
  }
  
  .sy-controls li a:link, .sy-controls li a:visited {
    opacity: 0.4;
  }
  
  .sy-controls li a:hover, .sy-controls li a:focus {
    opacity: 0.8;
    outline: none;
  }
  
  .sy-controls li a:after {
    content: "";
    background-image: url(assets/img/arrows.svg);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;
    text-indent: 0;
    line-height: 2.8em;
    color: #111;
    font-weight: 800;
    position: absolute;
    background-color: #fff;
    width: 2.8em;
    height: 2.8em;
    left: 50%;
    top: 50%;
    margin-top: -1.4em;
    margin-left: -1.4em;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%
  }
  
  @media only screen and (max-device-width: 600px) {
    .sy-controls {
      display: block;
    }
    .sy-controls li {
      min-width: 2.1em;
    }
    .sy-controls li a:after {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.7em;
      margin-left: -0.7em;
    }
  }
  
  .sy-caption-wrap {
    position: absolute;
    bottom: 2em;
    z-index: 12;
    left: 50%
  }
  
  .sy-caption-wrap .sy-caption {
    position: relative;
    left: -50%;
    background-color: rgba(0, 0, 0, 0.54);
    color: #fff;
    padding: 0.4em 1em;
    -webkit-border-radius: 1.2em;
    -moz-border-radius: 1.2em;
    -ms-border-radius: 1.2em;
    -o-border-radius: 1.2em;
    border-radius: 1.2em;
  }
  
  .sy-caption-wrap .sy-caption a:link, .sy-caption-wrap .sy-caption a:visited {
    color: #e24b70;
    font-weight: 600;
    text-decoration: none;
  }
  
  .sy-caption-wrap .sy-caption a:hover, .sy-caption-wrap .sy-caption a:focus {
    text-decoration: underline;
  }
  
  @media only screen and (max-device-width: 600px), screen and (max-width: 600px) {
    .sy-caption-wrap {
      left: 0;
      bottom: 0.4em;
    }
    .sy-caption-wrap .sy-caption {
      left: 0;
      padding: 0.2em 0.4em;
      font-size: 0.92em;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0;
    }
  }
  
  .sy-pager {
    overflow: hidden;
    *zoom: 1;
    display: block;
    width: 100%;
    margin: 1em 0 0;
    padding: 0;
    list-style: none;
    text-align: center;
  }
  
  .sy-pager li {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin: 0 1em 0 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%
  }
  
  .sy-pager li.sy-active a {
    background-color: #e24b70;
  }
  
  .sy-pager li a {
    width: 100%;
    height: 100%;
    display: block;
    background-color: #cccccc;
    text-indent: -9999px;
    -webkit-background-size: 2em;
    -moz-background-size: 2em;
    -o-background-size: 2em;
    background-size: 2em;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%
  }
  
  .sy-pager li a:link, .sy-pager li a:visited {
    opacity: 1.0;
  }
  
  .sy-pager li a:hover, .sy-pager li a:focus {
    opacity: 0.6;
  }
  
  .sy-filler {
    width: 100%
  }
  
  .sy-filler.ready {
    -webkit-transition: padding 600ms ease;
    -moz-transition: padding 600ms ease;
    -o-transition: padding 600ms ease;
    transition: padding 600ms ease;
  }
  
  .datepicker {
    top: 0;
    left: 0;
    padding: 4px;
    margin-top: 1px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  
  .datepicker:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 6px;
  }
  
  .datepicker:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    position: absolute;
    top: -6px;
    left: 7px;
  }
  
  .datepicker>div {
    display: none;
  }
  
  .datepicker table {
    width: 100%;
    margin: 0;
  }
  
  .datepicker td, .datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  
  .datepicker td.day:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  
  .datepicker td.day.disabled {
    color: #eeeeee;
  }
  
  .datepicker td.old, .datepicker td.new {
    color: #999999;
  }
  
  .datepicker td.active, .datepicker td.active:hover {
    color: #ffffff;
    background-color: #111;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  
  .datepicker td.active:hover, .datepicker td.active:hover:hover, .datepicker td.active:focus, .datepicker td.active:hover:focus, .datepicker td.active:active, .datepicker td.active:hover:active, .datepicker td.active.active, .datepicker td.active:hover.active, .datepicker td.active.disabled, .datepicker td.active:hover.disabled, .datepicker td.active[disabled], .datepicker td.active:hover[disabled] {
    color: #ffffff;
    background-color: #111;
  }
  
  .datepicker td span {
    display: block;
    width: 47px;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 2px;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  
  .datepicker td span:hover {
    background: #eeeeee;
  }
  
  .datepicker td span.active {
    color: #ffffff;
    background-color: #111;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  
  .datepicker td span.active:hover, .datepicker td span.active:focus, .datepicker td span.active:active, .datepicker td span.active.active, .datepicker td span.active.disabled, .datepicker td span.active[disabled] {
    color: #ffffff;
    background-color: #111;
  }
  
  .datepicker td span.old {
    color: #999999;
  }
  
  .datepicker th.switch {
    width: 145px;
  }
  
  .datepicker th.next, .datepicker th.prev {
    font-size: 21px;
  }
  
  .datepicker thead tr:first-child th {
    cursor: pointer;
  }
  
  .datepicker thead tr:first-child th:hover {
    background: #eeeeee;
  }
  
  .input-append.date .add-on i, .input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
  }
  
  .dropdown-menu.pull-right {
    right: 0;
    left: auto;
  }
  
  .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }
  
  .dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333333;
    white-space: nowrap;
  }
  
  .dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
  }
  
  .dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #428bca;
    outline: 0;
  }
  
  .dropdown-menu>.disabled>a, .dropdown-menu>.disabled>a:hover, .dropdown-menu>.disabled>a:focus {
    color: #999999;
  }
  
  .dropdown-menu>.disabled>a:hover, .dropdown-menu>.disabled>a:focus {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  }
  
  .open>.dropdown-menu {
    display: block;
  }
  
  .open>a {
    outline: 0;
  }
  
  .dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.428571429;
    color: #999999;
  }
  
  .table-condensed>thead>tr>th, .table-condensed>tbody>tr>th, .table-condensed>tfoot>tr>th, .table-condensed>thead>tr>td, .table-condensed>tbody>tr>td, .table-condensed>tfoot>tr>td {
    padding: 5px;
  }
  
  @media only screen and (min-width: 980px) {
    #top-carousel .owl-item .top-carousel-img-1 {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .site-header span {
      display: block;
      padding: 51px 0;
      padding-bottom: 50px;
    }
    .site-header-strapline {
      margin: -45px 0 0;
    }
    .site-nav {
      width: 100%;
      padding-bottom: 25px;
    }
    .site-nav ul {
      display: block;
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
    .site-nav li {
      display: inline;
      margin: 0;
      text-align: center;
    }
    .site-nav li.bullet {
      padding: 0 2px;
      background: transparent url(../../images/nav-bullet.png) no-repeat center;
      color: transparent;
      box-sizing: border-box;
    }
    .site-nav a {
      position: relative;
      font-size: 13px;
      font-size: 1.3rem;
      text-transform: uppercase;
      line-height: 24px;
      color: #111;
      text-decoration: none;
    }
    .site-nav a .underline {
      position: absolute;
      bottom: -3px;
      left: 0px;
      display: block;
      width: 0%;
      height: 1px;
      background-color: #111;
      transition: width 0.3s ease-in-out;
    }
    .site-nav a:hover .underline, .site-nav li.active .underline {
      width: 100%
    }
    .nav-wrapper.stuck .site-nav {
      padding: 15px 0;
      padding-bottom: 12px;
    }
    .nav-mobile {
      display: none;
    }
    .menu {
      margin: 0 30px;
    }
    .menu-wrapper {
      padding: 50px 10.5% 30px 10.5%
    }
    .menu-tabs {
      /*white-space: nowrap;*/
    }
    .menu-tabs li {
      padding: 0 20px;
    }
    .sub-form p {
      display: inline;
      padding: 0 8px;
    }
    .sub-form button {
      position: relative;
      top: 2px;
    }
    .site-footer {
      margin-top: 130px;
    }
    .site-footer p {
      padding: 0 40px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .col-right {
      float: right;
      margin-bottom: 0;
      width: 38%
    }
    .col-left {
      float: left;
      width: auto;
    }
    .col-right~.col-left {
      float: left;
      width: 50%;
    }
    .alt-block .col-left {
      margin-bottom: 0;
      width: 45%
    }
    .alt-block .col-right {
      width: 50%
    }
  }
  
  @media only screen and (min-width: 768px) and (device-width: 768px) {
    #copper {
      background: #dad7c8 url(../../images/copper-med.jpg) no-repeat scroll center top;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .menu {
      margin: 0 auto;
    }
    .menu-table tr td:first-child {
      padding-right: 0;
    }
    #map {
      height: 350px;
    }
    .social-links {
      position: absolute;
      left: 50%;
      bottom: 30px;
      margin-top: 0;
      width: 50%
    }
    .social-links ul {
      float: right;
    }
    .social-links p {
      float: left;
    }
    .reservation-widget {
      float: right;
    }
    .newsletter-message .message-content {
      padding: 80px;
    }
    .newsletter-message .message-content h1 {
      font-size: 70px;
      font-size: 7rem;
    }
    .newsletter-message .message-content p {
      font-size: 20px;
      font-size: 2rem;
    }
  }
  
  @media only screen and (min-width: 960px) {
    .wrapper {
      max-width: 760px;
    }
    .site-nav ul {
      max-width: 760px;
    }
    .site-nav li.bullet {
      padding-top: 2px;
    }
    .site-nav a {
      font-size: 16px;
      font-size: 1.6rem;
    }
    .col-right {
      width: 32.291%
    }
    .alt-block .col-left {
      width: 50%
    }
    .alt-block .col-right {
      width: 45%
    }
    .image-block {
      padding-top: 0;
      height: 528px;
    }
  }
  
  @media only screen and (min-width: 960px) and (device-width: 768px) {
    #copper {
      background: #dad7c8 url("../../images/copper.jpg") no-repeat scroll center top;
    }
  }
  
  @media only screen and (min-width: 960px) {
    .menu {
      margin: 0 auto;
    }
    .menu-wrapper {
      padding: 50px 7% 30px 7%;
    }
    #map {
      height: 400px;
    }
    .social-links {
      left: 55%;
      width: 45%
    }
  }
  
  @media only screen and (min-width: 960px) and (min-width: 1040px) {
    .nav-wrapper .tiny-logo {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 120px;
      height: 40px;
      background: transparent url(../../images/tiny-logo.png) no-repeat;
      text-indent: -9999em;
    }
    .nav-wrapper.stuck .tiny-logo {
      display: block;
    }
  }
  
  @media only screen and (min-width: 960px) {
    .lt-ie9 .wrapper {
      width: 960px;
    }
    .lt-ie9 .social-links a .border {
      display: none;
    }
    .lt-ie9 .nav-wrapper.stuck {
      width: 100%
    }
    .lt-ie9 .menu {
      border: 1px solid #d5d5d5;
    }
    .lt-ie8 .site-nav {
      display: block;
    }
    .lt-ie8 .site-nav ul {
      display: block;
      width: 750px;
    }
    .lt-ie8 .site-nav li {
      float: left;
      display: block;
    }
    .lt-ie8 .site-nav li.bullet {
      padding: 0 10px;
    }
    .lt-ie8 .site-nav a .underline {
      display: none;
    }
    .lt-ie8 .menu-table {
      width: 484px;
    }
    .lt-ie8 .menu-table tr td:first-child {
      width: 434px;
    }
    .lt-ie8 .menu-table tr td:last-child {
      width: 50px;
    }
    .lt-ie8 #copper {
      width: 100%;
      background: #dad7c8 url(../../images/copper.jpg) no-repeat fixed center top;
    }
    .twitter-list {
      min-height: 419px;
    }
  }
  
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5) {
    .site-header h1 a {
      background: transparent url(../../images/menu-logo@2x.png) no-repeat;
      background-size: 251px 88px;
    }
    .site-header span {
      display: block;
    }
    .site-header span a {
      background: transparent url(../../images/menu-logo@2x.png) no-repeat;
      background-size: 240px 85px;
    }
    .menu h2 {
      background: transparent url(../../images/menu-logo@2x.png) no-repeat;
      background-size: 251px 88px;
    }
    .social-links a {
      background: transparent url(../../images/social-links@2x.png) no-repeat;
      background-size: 35px 105px;
    }
    a.OT_Find_a_Table:link, a.OT_Find_a_Table:visited, a.OT_Find_a_Table:hover, a.OT_Find_a_Table:active {
      background: #f9f8f2 url(../../images/btn-toptable@2x.png) no-repeat !important;
      background-size: 135px 23px !important;
    }
  }
  
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 600px), only screen and (-o-min-device-pixel-ratio: 3 / 2) and (min-width: 600px), only screen and (min-device-pixel-ratio: 1.5) and (min-width: 600px) {
    .site-header h1 a {
      background: transparent url(../../images/fifteen-logo@2x.png) no-repeat;
      background-size: cover;
    }
    .site-header span {
      display: block;
    }
    .site-header span a {
      background: transparent url(../../images/fifteen-logo@2x.png) no-repeat;
      background-size: cover;
    }
  }
  
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width: 1040px), only screen and (-o-min-device-pixel-ratio: 3 / 2) and (min-width: 1040px), only screen and (min-device-pixel-ratio: 1.5) and (min-width: 1040px) {
    .nav-wrapper .tiny-logo {
      background: transparent url(../../images/tiny-logo@2x.png) no-repeat;
      background-size: 120px 40px;
    }
  }
  
  
  /* apprentice */
  
  #apprentice-box {
    font-family: "Gill Sans W04 Book", "Gill Sans", Helvetica, sans-serif;
    display: block;
    margin: 35px 0;
    padding: 20px;
    border: 1px solid #111;
    font-size: 18px;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none !important;
  }
  
  
  /* Menu */
  
  .menu-page #menu-info-tabs {
    display: block;
    padding: 0;
    margin: 0 -5px;
    text-align: center;
  }
  
  .menu-page #menu-info-tabs>li {
    display: inline-block;
    list-style: none;
    margin: 0 5px;
    padding: 10px 30px;
    border: 1px solid #111;
    cursor: pointer;
  }
  
  .menu-page #menu-info-tabs>li.active, .menu-page #menu-info-tabs>li:hover {
    background-color: #111;
    color: #fff;
  }
  
  .menu-page #tabs-container {
    margin-top: 20px;
  }
  
  .menu-page #tabs-container>ul {
    display: block;
    list-style: none;
  }
  
  .menu-page #tabs-container>ul>li {
    display: none;
  }
  
  .menu-page #tabs-container>ul>li a {
    text-transform: uppercase;
  }
  
  .menu-page .menu {
    box-shadow: none !important;
  }
  
  .menu-page .menu-wrapper {
    padding: 10px 7% 10px 7% !important;
    border-top: 1px solid;
  }
  
  
  /* Events page */
  
  .event-page #events-list {
    list-style: none;
  }
  
  .event-page #events-list>li.event-container {
    font-size: 1.6rem;
    line-height: 25px;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 40px;
  }
  
  .event-page #events-list>li.event-container>.container {
    display: table;
    width: 100%;
  }
  
  .event-page #events-list>li.event-container>.container>.image {
    display: table-cell;
    width: 30%;
    background-size: cover;
    background-position: center;
  }
  
  .event-page #events-list>li.event-container>.container>.details {
    display: table-cell;
    width: 70%;
    background: #fff;
  }
  
  .event-page #events-list>li.event-container>.container>.details>.details-wrapper {
    min-height: 200px;
    padding: 10px 20px;
  }
  
  .event-page ul#news-list {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .event-page ul#news-list>li {
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
  }
  
  .event-page ul#news-list>li:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 1px;
    background: #111;
  }
  
  .event-page ul#news-list>li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .event-page ul#news-list>li:last-child:after {
    display: none;
  }
  
  .event-page ul#news-list>li>.news-content {
    font-size: 1.3rem;
    line-height: 25px;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 20px;
  }
  
  @media only screen and (max-width: 900px) {
    .event-page #events-list>li.event-container>.container>.image {
      width: 50%;
    }
    .event-page #events-list>li.event-container>.container>.details {
      width: 50%;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .event-page #events-list>li.event-container>.container {
      display: block;
      width: 100%;
      float: none;
      overflow: hidden;
      padding-top: 200px;
      position: relative;
    }
    .event-page #events-list>li.event-container>.container>.image {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      min-height: 200px;
    }
    .event-page #events-list>li.event-container>.container>.details {
      display: block;
      width: 100%;
      float: left;
    }
  }
  
  
  /* Pilonce mods */
  
  .relative {
    position: relative;
  }
  
  .top-carousel {
    height: 400px;
  }
  
  .owl-carousel {
    display: none;
    width: 100%;
    z-index: 1;
  }
  
  .owl-carousel .item {
    width: 760px;
  }
  
  .overImage {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
  }
  
  .owl-item.active .overImage {
    display: none;
  }
  
  .top-carousel-img-1 {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .top-carousel-img-height {
    height: 400px;
  }
  
  .top-carousel, .top-carousel-img-height {
    background-position: center;
  }
  
  @media screen and (max-width: 980px) {
    .top-carousel-img-1 {
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    .top-carousel, .top-carousel-img-height {
      height: 360px;
    }
    .owl-carousel .item {
      width: auto;
    }
  }
  
  @media screen and (max-width: 500px) {
    .top-carousel, .top-carousel-img-height {
      height: 220px;
    }
  }
  
  .menu.dropdown>button {
    position: relative;
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #000;
    outline: none !important;
    background-color: #fff !important;
    text-align: left;
    text-transform: uppercase;
  }
  
  .menu.dropdown>button>.label {
    display: inline-block;
    margin-top: 2px;
    font-size: 14px;
    font-size: 1.4rem;
  }
  
  .menu.dropdown>button>.caret {
    position: absolute;
    top: 6px;
    right: 12px;
    font-size: 22px;
    -ms-transform: rotate(90deg);
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    /* Chrome, Safari, Opera */
    transform: rotate(90deg);
  }
  
  .menu.dropdown .dropdown-menu {
    min-width: 99.7% !important;
    border-radius: 0;
  }
  
  .menu.dropdown .dropdown-menu>li>a {
    text-decoration: none !important;
  }
  
  #nav_dropdown1,
  #nav_dropdown_food {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  #nav_dropdown1>ul.dropdown-menu,
  #nav_dropdown_food>ul.dropdown-menu {
    position: relative;
    top: 0;
    left: 50%;
    display: block !important;
    width: 760px !important;
    margin-left: -380px;
  }
  
  #nav_dropdown1>ul.dropdown-menu>li>a,
  #nav_dropdown_food>ul.dropdown-menu>li>a {
    text-transform: none !important;
    text-decoration: none !important;
  }
  
  /* modify style */
  
  .tour360 {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  .tour360 > .overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.85)
  }
  .tour360 > .overlay > .button {
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -110px;
    font-weight: bold;
    text-decoration: none;
  }
  .tour360 iframe {
    width: 100%;
    height: 450px;
  }
  
  @media only screen and (max-width: 600px) {
    .tour360 iframe {
      height: 350px;
    }
  }
  
  /* Pilonce mods */
  