.m-0 {
  margin: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

/*========== Mobile First Method  ==========*/
/*==========  Non-Mobile First Method  ==========*/
body {
  padding-bottom: 100px;
}

.site-header img {
  display: block;
  width: 300px;
  margin: 0px auto 30px auto;
}

@media only screen and (max-width: 768px) {
  .content-block h1 {
    margin-top: 30px !important;
  }
}

@media only screen and (min-width: 768px) {
  .content-block.about .col-left {
    width: 65%;
  }
}

.content-block.about .col-left p {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .content-block.about .col-right {
    width: 30%;
  }
}

footer.site-footer {
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

footer.site-footer p {
  padding: 20px 0px;
  margin-bottom: 0px;
}

.menu {
  max-width: 760px !important;
}

.form-group {
  position: relative;
  margin-bottom: 35px;
}

@media only screen and (max-width: 768px) {
  .form-group {
    margin-bottom: 20px;
  }
}

.form-group label {
  width: 100%;
  display: block;
  margin-bottom: 5px;
}

.form-group input, .form-group select {
  width: 100%;
  border: none;
  height: 30px;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .form-group input, .form-group select {
    height: 50px;
    font-size: 16px;
  }
}

.form-group i {
  position: absolute;
  right: 10px;
  top: 28px;
  color: #b4a490;
}

@media only screen and (max-width: 768px) {
  .form-group i {
    top: 38px;
  }
}

button#reservation-btn {
  margin: 0 auto;
  display: block;
  padding: 10px 55px;
  background-color: #b5a491;
  text-transform: uppercase;
  color: white;
}

.vcard {
  text-transform: none !important;
}

a.whatsapp {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 200px;
  height: auto;
  z-index: 999;
}

.message-reservation {
  background-color: #51bb51;
  color: white;
  height: 30px;
  width: 90%;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: center;
}

#form-loader {
  text-align: center;
}

#form-loader img {
  display: inline-block;
}

.hidden-custom {
  display: none !important;
}

.datepicker {
  min-width: 220px;
}

@media only screen and (max-width: 767px) {
  .datepicker {
    min-width: calc(100% - 40px);
  }
}

.iti.iti--allow-dropdown {
  width: 100% !important;
}

.iti__flag {
  background-image: url("/themes/medusa-restaurant/assets/build/telinput-img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("/themes/medusa-restaurant/assets/build/telinput-img/flags@2x.png");
  }
}

/*# sourceMappingURL=customize.css.map */
